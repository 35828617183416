// Convert an absolute URL to a relative path.
const relativeUrl = ( options, next ) => {
	let url = options.url;
	let path = options.path;

	if ( typeof url === 'string' ) {
		const parsedUrl = new URL( url );
		path = parsedUrl.pathname + parsedUrl.search;
		url = undefined;
	}

	return next( {
		...options,
		url,
		path,
	} );
};

export default relativeUrl;
