/**
 * External dependencies
 */
import React, { forwardRef } from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import { X as CloseIcon } from 'react-feather';

/**
 * Internal dependencies
 */
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';

import './Modal.scss';

if ( process.env.NODE_ENV !== 'test' ) {
	ReactModal.setAppElement( '#root' );
}

interface ModalProps {
	onClose: ( event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> ) => void;
	isOpen: boolean;
	contentLabel: string;
	size?: 'full' | 'fluid' | 'fluid-small';
	className?: string;
	children?: React.ReactNode;
	ref?: React.Ref<ReactModal>;
	shouldCloseOnOverlayClick?: boolean;
}

interface ModalSizes {
	SIZE_FLUID_SMALL?: 'fluid-small';
	SIZE_FULL?: 'full';
	SIZE_FLUID?: 'fluid';
}

const Modal: React.FC<ModalProps> & ModalSizes = forwardRef<ReactModal, ModalProps>( ( {
	children,
	isOpen = false,
	shouldCloseOnOverlayClick = true,
	size = 'fluid',
	onClose,
	className,
	...additionalProps
}, ref: React.Ref<any> ) => {
	const classes = classnames(
		'modal__content',
		{ [ `modal__content--size-${ size }` ]: size },
		className,
	);

	return (
		<ReactModal
			ref={ ref }
			isOpen={ isOpen }
			{ ...additionalProps }
			portalClassName="modal"
			className={ classes }
			overlayClassName="modal__overlay"
			htmlOpenClassName="modal--open"
			bodyOpenClassName={ null }
			onRequestClose={ onClose }
			shouldCloseOnOverlayClick={ shouldCloseOnOverlayClick }
		>
			<div className="modal__header">
				<button className="modal__close" type="button" onClick={ onClose }>
					<CloseIcon role="img" aria-hidden="true" focusable="false" />
					<VisuallyHidden>Schliessen</VisuallyHidden>
				</button>
			</div>
			{ children }
		</ReactModal>
	);
} );

Modal.SIZE_FULL = 'full';
Modal.SIZE_FLUID = 'fluid';
Modal.SIZE_FLUID_SMALL = 'fluid-small';

export default Modal;
