/**
 * External dependencies
 */
import React from 'react';
import classnames from 'classnames';
import { NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps } from 'react-router-dom';

interface NavLinkProps {
	className?: string;
	children: React.ReactNode;
}

const NavLink = ( {
	className,
	children,
	...otherProps
}: NavLinkProps & RouterNavLinkProps ) => {
	return (
		<RouterNavLink className={ classnames( 'navigation__item', className ) } activeClassName="navigation__item--active" { ...otherProps }>
			{ children }
		</RouterNavLink>
	);
};

export default NavLink;
