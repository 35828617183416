// Add API root URL.
const rootUrl = ( rootURL ) => ( options, next ) => {
	let url = options.url;
	const path = options.path;

	if ( typeof path === 'string' ) {
		url = rootURL + path;
	}

	return next( {
		...options,
		url,
	} );
};

export default rootUrl;
