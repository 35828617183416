/**
 * Returns a merged array of item IDs, given details of the received paginated
 * items. The array is sparse-like with `undefined` entries where holes exist.
 *
 * @param {?Array<number>} itemIds     Original item IDs (default empty array).
 * @param {number[]}       nextItemIds Item IDs to merge.
 * @param {number}         page        Page of items merged.
 * @param {number}         perPage     Number of items per page.
 * @return {number[]} Merged array of item IDs.
 */
export default function getMergedItemIds( itemIds, nextItemIds, page, perPage ) {
	const receivedAllIds = page === 1 && perPage === -1;
	if ( receivedAllIds ) {
		return nextItemIds;
	}
	const nextItemIdsStartIndex = ( page - 1 ) * perPage;

	// If later page has already been received, default to the larger known
	// size of the existing array, else calculate as extending the existing.
	const size = Math.max(
		itemIds.length,
		nextItemIdsStartIndex + nextItemIds.length,
	);

	// Preallocate array since size is known.
	const mergedItemIds = new Array( size );

	for ( let i = 0; i < size; i++ ) {
		// Preserve existing item ID except for subset of range of next items.
		const isInNextItemsRange =
			i >= nextItemIdsStartIndex &&
			i < nextItemIdsStartIndex + nextItemIds.length;

		mergedItemIds[ i ] = isInNextItemsRange ?
			nextItemIds[ i - nextItemIdsStartIndex ] :
			itemIds[ i ];
	}

	return mergedItemIds;
}
