/**
 * External dependencies
 */
import React, { useState } from 'react';
import classnames from 'classnames';
import { omit } from 'lodash';
import { useTransition, animated } from 'react-spring/web.cjs';

/**
 * Internal dependencies
 */
import Snackbar from './Snackbar';
import { Notice } from '../../store/types';

import './Snackbar.scss';

interface SnackbarListProps {
	className?: string;
	notices: Notice[];
	onRemove?( id: string ): void;
	children?: React.ReactNode;
}

function SnackbarList( {
	notices,
	className,
	children,
	onRemove = () => {},
}: SnackbarListProps ) {
	const [ refMap ] = useState( () => new WeakMap() );

	// @ts-ignore
	const transitions = useTransition(
		notices,
		( notice ) => notice.id,
		{
			from: {
				opacity: 0,
				height: 0,
			},
			enter: ( item ) => async ( next ) => await next( {
				opacity: 1,
				height: refMap.get( item )?.offsetHeight || 60,
			} ),
			leave: () => async ( next ) => {
				await next( { opacity: 0 } );
				await next( { height: 0 } );
			},
			immediate: false,
		},
	);

	className = classnames( 'snackbar-list', className );
	const removeNotice = ( notice: Notice ) => () => onRemove( notice.id );

	return (
		<div className={ className }>
			{ children }
			{ transitions.map( ( {
				item: notice,
				key,
				props: style,
			} ) => (
				<animated.div key={ key } style={ style }>
					<div
						className="snackbar-list__notice-container"
						ref={ ( ref ) => ref && refMap.set( notice, ref ) }
					>
						<Snackbar
							{ ...omit( notice, [ 'content' ] ) }
							onRemove={ removeNotice( notice ) }
						>
							{ notice.content }
						</Snackbar>
					</div>
				</animated.div>
			) ) }
		</div>
	);
}

export default SnackbarList;
