/**
 * External dependencies
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';

import './Loader.scss';

interface LoaderProps {
	className?: string;
	isPositionRelative?: boolean;
}

const Loader = ( {
	className, isPositionRelative,
}: LoaderProps ) => {
	return (
		<div className={ classnames( 'loader', className, { 'loader__position-relative': isPositionRelative } ) }>
			<div className="loader__content">
				<VisuallyHidden>Lade Inhalte…</VisuallyHidden>
				<div className="loader__spinner">
					<svg viewBox="25 25 50 50">
						<circle
							cx="50"
							cy="50"
							r="20"
							fill="none"
							strokeWidth="2"
							strokeMiterlimit="10"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
};

export default Loader;
