/**
 * External dependencies
 */
import React, { forwardRef } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import useComponentId from '../../hooks/useComponentId';
import BaseControl, { BaseControlProps } from '../BaseControl/BaseControl';

import './TextControl.scss';

export interface TextControlProps extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'id' | 'ref'>, Omit<BaseControlProps, 'children'> {
	className?: string;
	value?: string;
	name?: string;
	onChange?( value: string, name: string, event: any ): void;
	type?: string;
	isInvalid?: boolean;
}

const TextControl = ( {
	label,
	value,
	help,
	name = '',
	className,
	onChange,
	type = 'text',
	isInvalid,
	required,
	disabled,
	error,
	...props
}: TextControlProps, ref: React.Ref<any> ) => {
	const instanceId = useComponentId();
	const id = `text-control-${ instanceId }`;
	const onChangeValue = ( event ) => onChange( event.target.value, name, event );
	const classes = classnames( 'text-control__input', { 'text-control__input--is-invalid': isInvalid } );

	return (
		<BaseControl label={ label } id={ id } help={ help } className={ className } required={ required } disabled={ disabled } error={ error }>
			<input
				ref={ ref }
				className={ classes }
				type={ type }
				id={ id }
				value={ value || '' }
				onChange={ onChangeValue }
				aria-describedby={ !! help ? id + '__help' : undefined }
				required={ required }
				disabled={ disabled }
				{ ...props }
			/>
		</BaseControl>
	);
};

export default forwardRef( TextControl );
