/**
 * External dependencies
 */
import { useEffect } from 'react';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT.toUpperCase();
const WIDGET_SECRET = process.env?.[ `REACT_APP_USERLIKE_WIDGET_${ ENVIRONMENT }_SECRET` ] || '';

const Userlike = () => {
	useEffect( () => {
		if ( ! WIDGET_SECRET ) {
			return;
		}

		const script = document.createElement( 'script' );
		script.src = `https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/${ WIDGET_SECRET }.js`;
		script.defer = true;
		document.body.appendChild( script );
	}, [] );

	return null;
};

export default Userlike;
