/**
 * External dependencies
 */
import React from 'react';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

declare let process : {
	env: {
		REACT_APP_MATOMO_BASE: string;
		REACT_APP_MATOMO_SITEID: number;
		REACT_APP_MATOMO_DISABLED: string;
	};
};

const instance = createInstance( {
	urlBase: process.env.REACT_APP_MATOMO_BASE,
	siteId: process.env.REACT_APP_MATOMO_SITEID,
	disabled: process.env.REACT_APP_MATOMO_DISABLED === 'true',
} );

const MatomoAnalyticsProvider = ( props ) => (
	<MatomoProvider value={ instance }>
		{ props.children }
	</MatomoProvider>
);

export default MatomoAnalyticsProvider;
