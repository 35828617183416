/**
 * External dependencies
 */
import { useRef } from 'react';

let id = 0;
const getId = () => ++id;

export default function useComponentId() {
	const idRef = useRef( getId() );
	return idRef.current;
}
