/**
 * For use in fetch().then() calls.
 *
 * @param {Response} response
 */
export const checkResponseStatus = ( response ) => {
	if ( response.status >= 200 && response.status < 300 ) {
		return response;
	}

	throw response;
};
