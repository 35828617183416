/**
 * External dependencies
 */
import { useRef, useEffect } from 'react';

export default function usePrevious( value: any, resetOnUnmount: boolean = false ) {
	const ref = useRef( null );

	useEffect( () => {
		ref.current = value;

		return () => {
			if ( resetOnUnmount ) {
				ref.current = null;
			}
		};
	}, [ value, resetOnUnmount ] );

	return ref.current;
}
