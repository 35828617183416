/**
 * External dependencies
 */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
	const {
		pathname,
		hash,
	} = useLocation();

	useEffect( () => {
		if ( hash ) {
			const element = document.getElementById( hash.replace( '#', '' ) );
			if ( element ) {
				element.scrollIntoView();
				return;
			}
		}

		window.scroll( 0, 0 );
	}, [ hash, pathname ] );

	return null;
};

export default ScrollToTop;
