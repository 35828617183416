/**
 * External dependencies
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './BaseControl.scss';

export interface BaseControlProps {
	className?: string;
	id?: string;
	label?: string;
	help?: string;
	inline?: boolean;
	required?: boolean;
	disabled?: boolean;
	error?: string;
	children: React.ReactNode;
}

function BaseControl( {
	id,
	label,
	help,
	className,
	inline,
	required,
	disabled,
	error,
	children,
}: BaseControlProps ) {
	const classes = classnames( 'base-control', className, { 'base-control--inline': inline } );

	return (
		<div className={ classes }>
			<div className="base-control__field">
				{ label && id && <label className="base-control__label" htmlFor={ id }>{ label }{ required ? ' *' : '' }</label> }
				{ label && ! id && <BaseControl.VisualLabel>{ label }{ required ? ' *' : '' }</BaseControl.VisualLabel> }
				{ children }
			</div>
			{ !! help && <p id={ id + '__help' } className="base-control__help">{ help }</p> }
			{ !! error && ! disabled && <p id={ id + '__error' } className="base-control__error">{ error }</p> }
		</div>
	);
}

interface BaseControlVisualLabelProps {
	className?: string;
	children: React.ReactNode;
}

BaseControl.VisualLabel = ( {
	className,
	children,
}: BaseControlVisualLabelProps ) => {
	className = classnames( 'base-control__label', className );
	return (
		<span className={ className }>
			{ children }
		</span>
	);
};

export default BaseControl;
