/**
 * External dependencies
 */
import React from 'react';
import classnames from 'classnames';
import { omit } from 'lodash';

/**
 * Internal dependencies
 */
import Notice from './Notice';
import { Notice as NoticeInterface } from '../../store/types';

interface NoticeListProps {
	className?: string;
	notices: NoticeInterface[];
	onRemove?( id: string ): void;
	children?: React.ReactNode;
}

function NoticeList( {
	notices,
	onRemove = () => {},
	className,
	children,
}: NoticeListProps ) {
	const removeNotice = ( id: string ) => () => onRemove( id );

	className = classnames( 'notice-list', className );

	return (
		<div className={ className }>
			{ children }
			{ [ ...notices ].reverse().map( ( notice: NoticeInterface ) => (
				<Notice
					{ ...omit( notice, [ 'content' ] ) }
					key={ notice.id }
					onRemove={ removeNotice( notice.id ) }
				>
					{ notice.content }
				</Notice>
			) ) }
		</div>
	);
}

export default NoticeList;
