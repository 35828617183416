/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import './LoadingIndicator.scss';

const LoadingIndicator = () => {
	return (
		<div aria-hidden="true" className="loading-indicator"><span></span><span></span><span></span></div>
	);
};

export default LoadingIndicator;
