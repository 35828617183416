/**
 * For use in fetch().then() calls.
 *
 * @param {Response} response
 */
export const parseResponse = ( response ) => {
	if ( response.status === 204 ) {
		return null;
	}

	return response.json ? response.json() : Promise.reject( response );
};
