/**
 * External dependencies
 */
import React, { useContext } from 'react';
import classnames from 'classnames';
import { Search as SearchIcon } from 'react-feather';
/**
 * Internal dependencies
 */
import { HeaderContext } from './HeaderContext';

import './SearchButton.scss';

const SearchButton = ( { className } ) => {
	const {
		isSearchFormOpen,
		openSearchForm,
		closeSearchForm,
	} = useContext( HeaderContext );

	const classes = classnames( 'search-button', className );

	return (
		<button type="button" className={ classes } onClick={ isSearchFormOpen ? closeSearchForm : openSearchForm }>
			<SearchIcon role="img" className="search-button__icon" aria-hidden="true" focusable="false" /> Suche
		</button>
	);
};

export default SearchButton;
