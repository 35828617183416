/**
 * For use in fetch().catch() calls.
 *
 * @param {Response} response
 */
export const parseFailureResponse = ( response ) => {
	const requestError = {
		code: 'request_error',
		message: response.message || 'The request was not successful.',
	};

	if ( response instanceof TypeError ) {
		throw requestError;
	}

	const invalidJsonError = {
		code: 'invalid_json',
		message: 'The response is not a valid JSON response.',
	};

	if ( ! response || ! response.json ) {
		throw invalidJsonError;
	}

	return response.json()
		.catch( () => {
			throw invalidJsonError;
		} )
		.then( ( error ) => {
			const unknownError = {
				code: 'unknown_error',
				message: 'An unknown error occurred.',
			};

			throw error || unknownError;
		} );
};
