/**
 * External dependencies
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './VisuallyHidden.scss';

interface VisuallyHiddenProps {
	className?: string;
	as?: React.ElementType<any>;
	children: React.ReactNode;
}

const VisuallyHidden = ( {
	as: Component = 'span',
	className,
	children,
	...rest
}: VisuallyHiddenProps & React.HTMLProps<any> ) => {
	const classes = classnames( 'screen-reader-text', className );

	return (
		<Component
			{ ...rest }
			className={ classes }
		>
			{ children }
		</Component>
	);
};

export default VisuallyHidden;
