/**
 * External dependencies
 */
import React, { useEffect, forwardRef } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import Button from '../Button/Button';
import { NoticeAction } from '../../store/types';

import './Snackbar.scss';

const NOTICE_TIMEOUT = 10000;

interface SnackbarProps {
	className?: string;
	children?: React.ReactNode;
	onRemove?(): void;
	action?: NoticeAction;
	isPersistent?: boolean;
}

function Snackbar( {
	className,
	children,
	action,
	isPersistent = false,
	onRemove = () => {},
}: SnackbarProps, ref ) {
	useEffect( () => {
		if ( isPersistent ) {
			return;
		}

		const timeoutHandle = setTimeout( () => {
			onRemove();
		}, NOTICE_TIMEOUT );

		return () => clearTimeout( timeoutHandle );
	}, [ onRemove, isPersistent ] );

	const classes = classnames( className, 'snackbar' );

	return (
		<div
			ref={ ref }
			className={ classes }
			onClick={ onRemove }
			tabIndex={ 0 }
			role="button"
			onKeyPress={ onRemove }
			aria-label={ 'Ausblenden' }
		>
			<div className="snackbar__content">
				{ children }
				{ !! action && (
					<Button
						href={ action.url }
						isTertiary
						onClick={ ( event ) => {
							event.stopPropagation();
							if ( action.onClick ) {
								action.onClick( event );
							}
						} }
						className="snackbar__action"
					>
						{ action.label }
					</Button>
				) }
			</div>
		</div>
	);
}

export default forwardRef( Snackbar );
