/**
 * External dependencies
 */
import React, { forwardRef } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './Form.scss';

const Form = ( {
	className,
	children,
	isColLayout,
	...props
}, ref ) => {
	const classes = classnames( 'form', className, { 'form--col-layout': isColLayout } );

	return (
		<form className={ classes } ref={ ref } { ...props }>
			{ children }
		</form>
	);
};

export default forwardRef( Form );
