/**
 * External dependencies
 */
import React, { Component, createRef } from 'react';

/**
 * Internal dependencies
 */
import Button from '../Button/Button';

import './ErrorBoundary.scss';

interface ErrorBoundaryProps {
}

interface ErrorBoundaryState {
	error: any;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	private refTextarea;

	constructor( props ) {
		super( props );

		this.reboot = this.reboot.bind( this );
		this.copyError = this.copyError.bind( this );

		this.state = { error: null };

		this.refTextarea = createRef();
	}

	componentDidCatch( error ) {
		this.setState( { error } );
	}

	reboot() {
		window.location.reload();
	}

	redirectToDashboard() {
		window.location.href = window.location.origin;
	}

	copyError() {
		this.refTextarea.current.select();
		try {
			document.execCommand( 'copy' );
		} catch ( e ) {}
	}

	render() {
		const { error } = this.state;
		if ( ! error ) {
			return this.props.children;
		}

		/* eslint-disable jsx-a11y/accessible-emoji */
		return (
			<div className="error-boundary">
				<div className="error-boundary__content">
					<h1>Ein unerwarteter Fehler ist aufgetreten. 😭</h1>
					<p>Bitte lade die Seite neu oder gehe zurück zu Startseite.</p>

					<p>
						<Button isPrimary onClick={ this.reboot }>Seite neuladen</Button>
						<Button onClick={ this.redirectToDashboard }>Zur Startseite wechseln</Button>
					</p>
					<p>Sollte der Fehler bestehen bleiben, <Button isLink onClick={ this.copyError }>kopiere hier die Fehlerdetails in die Zwischenablage</Button> und sende diese an <a href="mailto:feedback@berufswahl.zh.ch">feedback@berufswahl.zh.ch.</a></p>
					<textarea ref={ this.refTextarea } className="error-boundary__stack" defaultValue={ error.stack } />
				</div>
			</div>
		);
	}
}

export default ErrorBoundary;
